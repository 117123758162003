<template>
  <div class="container">
    <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
      <a-breadcrumb-item>用餐人员管理</a-breadcrumb-item>
      <a-breadcrumb-item>现场点餐</a-breadcrumb-item>
    </a-breadcrumb>
    <a-tabs default-active-key="1" v-model="activeKey" @change="changeTab">
      <a-tab-pane :key="1" tab="订单列表"> 
        <div class="mainBox">
          <!-- 控制区域 :timeRange="timeRange"-->
          <div class="control_box">
            <Search-bar
              :timeRange="timeRange2"
              :inputs="inputs2"
              :selects="selects2"
              @search="search2"
              @reset="search2"
            />
          </div>
          <a-button
            class="export-btn"
            v-if="!reGetExportData"
            type="primary"
            :icon="ExportLoading ? 'loading' : 'printer'"
            @click="downloadExcel"
            :disabled="ExportLoading"
            >{{ ExportLoading ? "加载中.." : "导出" }}</a-button
          >
          <a-button class="export-btn" v-else @click="getExportData">重新获取导出数据</a-button>
          <!-- 内容区域 -->
          <div class="show_box">
            <a-table
              :columns="columns2"
              :data-source="outSideOrders"
              :loading="loading2"
              :rowKey="(outSideOrders) => outSideOrders.id"
              :pagination="pagination2"
              @change="handleTableChange2"
            >
              <span slot="meal" slot-scope="record">
                {{
                  record.get_meal_time.title
                }}
              </span>
              <span slot="realname" slot-scope="record">
                {{
                  record.get_user.realname
                }}
              </span>
              <span slot="mobile" slot-scope="record">
                {{
                  record.get_user.mobile
                }}
              </span>
              <span slot="check_status" slot-scope="record">
                {{
                  record.check_status == 1
                    ? "已核销"
                    : "待核销"
                }}
              </span>
              <span slot="updatedTime" slot-scope="record">
                {{ record.check_status == 1 ? record.updated_at : "待核销" }}
              </span>
              <!-- <template slot="action" slot-scope="record">
                <a-button
                  :disabled="record.status != 1"
                  type="link"
                  icon="delete"
                  @click="deleteType(record)"
                />
              </template> -->
            </a-table>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="获取点餐码" v-if="!agent_inst"> 
        <div class="mainBox" style="padding: 24px">
          <div class="not_agent_inst">
            <a-button  type="primary" @click="showPic"
              >获取点餐码</a-button
            >

            <a-modal width="410px"  v-model="picVisible" :footer="null"  @cancel="closeQrcode">
              <div style="text-align: center;width:360px;">
                <canvas id="printCanvas" width="360" height="480"></canvas>
                <a-button
                  v-if="url"
                  style="margin-top: 10px"
                  type="primary"
                  icon="printer"
                  @click="downloadQrcode"
                  >保存图片到本地</a-button>
              </div>
            </a-modal>
          </div>
          <div class="scene-switch" style="display: flex;justify-content: flex-start;margin-top: 20px;">
            <div style="margin-right: 15px;">临时停用二维码</div>
            <a-switch v-model:checked="checked" @change="() => changeStatus()" />
            <div style="margin-left: 8px;">开启后，员工暂时无法通过扫码现场点餐</div>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>

     <!-- 飞鹅列表 -->
    <a-modal v-model="isShowDeviceList" title="飞鹅列表" :footer="null">
      <a-table :loading="deviceListLoading" :columns="deviceListColumns" :data-source="deviceList" :pagination="false" key="id">
        <template slot="action" slot-scope="record">
          <a-button type="link" @click="checkItem(record)">绑定</a-button>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getSceneMealQrcode,listOutsiderOrders,mealtimeListWithout, getDeviceList, getSceneStatus, setSceneStatus} from "@/request/api";
import SearchBar from "@/components/common/SearchBar.vue";
import util from "@/utils/qrcode";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {SearchBar},
  data() {
    return {
      checked:false,
      url: "",
      picVisible: false,
      agent_inst: false,
      code: "",
      activeKey: 1,
      outSideParam: {
        page: 1,
        begin_date: "",
        end_date: "",
        status: "",
        meal_id: "",
        mobile: "",
      },
      outSideOrders: [],
      loading2: false,
      ExportLoading: false,
      reGetExportData: false,
      dataForExport: [],
      columns2: [
        {
          title: "用餐时段",
          key: "meal",
          scopedSlots: { customRender: "meal" },
        },
        // {
        //   title: "用餐类型",
        //   key: "data_from",
        //   scopedSlots: { customRender: "mealType" },
        // },
        {
          title: "订单编号",
          key: "order_number",
          dataIndex: "order_number",
        },
        {
          title: "订单明细",
          key: "orderDetails",
          dataIndex: "orderDetails",
        },

        {
          title: "金额",
          key: "money",
          dataIndex: "money",
        },
        {
          title: "姓名",
          key: "realname",
          scopedSlots: { customRender: "realname" },
        },
        {
          title: "手机",
          key: "mobile",
          scopedSlots: { customRender: "mobile" },
        },
        {
          title: "用餐日期",
          key: "repast_date",
          dataIndex: "repast_date",
        },
        {
          title: "用餐状态",
          key: "status",
          scopedSlots: { customRender: "check_status" },
        },
        {
          title: "报餐时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "核销时间",
          key: "updated_at",
          scopedSlots: { customRender: "updatedTime" },
        },
      ],
      pagination2: {},
      timeRange2: {
        show: true,
        placeholderStart: "开始时间",
        placeholderEnd: "结束时间",
        rangeLimit: true,
        // disabledBeforeNov: true,
      },
      inputs2: [
        {
          placeholder: "手机号码",
          model: "mobile",
          width: 100,
        }
      ],
      selects2: [
        {
          model: "meal_id",
          placeholder: "用餐时段",
          width: 100,
          options: [
            {
              key: "",
              value: "用餐时段",
            },
          ],
        },
        {
          model: "status",
          placeholder: "就餐状态",
          width: 100,
          options: [
            {
              key: "",
              value: "就餐状态",
            },
            {
              key: 0,
              value: "待就餐",
            },
            {
              key: 1,
              value: "已就餐",
            }
          ],
        },
      ],
      isShowDeviceList: false,
      deviceList: [],
      deviceListLoading: false,
      deviceListColumns: [
        {
          title: "设备名称",
          key: "printer_name",
          dataIndex: "printer_name",
        },
        {
          title: "设备KEY",
          key: "printer_key",
          dataIndex: "printer_key",
        },
        {
          title: "设备SN",
          key: "printer_sn",
          dataIndex: "printer_sn",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeStatus () {
      this.changeSceneStatus();
    },
    getSceneStatus() {
			getSceneStatus().then(res => {
        let { data } = res;
        console.log(data);
        if(data.data == 1){
          this.checked = true;
        }
      }).catch(err => {
        // this.$message.error(err)
        // console.log(err);
      })
		},
		changeSceneStatus(e){
			let parame = {
        status:this.checked?1:0
      };
      setSceneStatus(parame).then(res => {
        console.log(data);
      }).catch(err => {
        // this.$message.error(err)
        // console.log(err);
      })
		},
    DownloadPic() {
      const a = document.createElement("a");
      a.setAttribute("href", this.url);
      a.click();
    },
    showPic() {
      this.$confirm({
        title: '是否绑定飞鹅SN码？',
        onOk: () => {
          this.isShowDeviceList = true
          this.deviceListLoading = true
          getDeviceList().then(res => {
            let { data } = res;
            this.deviceList = data
            this.deviceListLoading = false
          }).catch(err => {
            this.$message.error(err)
          })
        },
        onCancel: () => {
          getSceneMealQrcode()
          .then((res) => {
            this.url = `https://styf.oss-cn-shenzhen.aliyuncs.com/business/qrcode/${this.global.userInfo.inst_id}/sceneMeal.jpg`;
            if(process.env.NODE_ENV === 'development'){
              this.url = `https://styf.oss-cn-shenzhen.aliyuncs.com/business/testqrcode/${this.global.userInfo.inst_id}/sceneMeal.jpg`;
            }
            this.picVisible = true;
            let inst_info = JSON.parse(localStorage.getItem("inst"));
            setTimeout(()=>{
              let canvas = document.getElementById('printCanvas');
              //canvas:Canvas元素； imgUrl:二维码URL； title: 食堂名称; id:食堂ID
              util.generateQrcode(canvas,this.url,inst_info.title,inst_info.id,'微信扫码进行核销用餐');
            },500);
            return
          })
          .catch((err) => {
            this.$message.info("请重新点击获取点餐码");
          });
        }
      })
    },
    downloadQrcode(){
        let canvas = document.getElementById('printCanvas')
        let data = canvas.toDataURL("image/png")
        util.saveCanvasImg(data)
    },
    closeQrcode(){
      let canvas = document.getElementById('printCanvas');
      qrcodeTools.removeQrcode(canvas);
      this.picVisible = false;
    },
    //选择设备
    checkItem(r) {
      this.isShowDeviceList = false;
      let { printer_sn } = r;
      //销毁之前的二维码
      let canvas = document.getElementById('printCanvas');
      util.removeQrcode(canvas);
      //获取食堂信息
      let inst_info = JSON.parse(localStorage.getItem("inst"));
      getSceneMealQrcode({printer_sn})
      .then((res) => {
        this.url = `https://styf.oss-cn-shenzhen.aliyuncs.com/business/qrcode/${this.global.userInfo.inst_id}/sceneMeal_${printer_sn}.jpg`;
        if(process.env.NODE_ENV === 'development'){
          this.url = `https://styf.oss-cn-shenzhen.aliyuncs.com/business/testqrcode/${this.global.userInfo.inst_id}/sceneMeal_${printer_sn}.jpg`;
        }

        //绘制二维码
        util.generateQrcode(canvas,this.url,inst_info.title,inst_info.id,'微信扫码进行核销用餐');
      })
      .catch((err) => {
        this.$message.info("请重新点击获取点餐码");
      });
    },

    changeTab(key) {
      this.activeKey = key;
      if (key == 1) {
        this.getOutSideOrders();
        this.getExportData();
      } 
    },
    // 刷新
    search2(val) {
      this.outSideParam = {
        page: 1,
        status: val.select.status === "" ? "" : val.select.status,
        meal_id: val.select.meal_id ? val.select.meal_id : "",
        mobile: val.input.mobile ? val.input.mobile : "",
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination2.current = 1;
      this.getOutSideOrders();
      this.getExportData();
    },
     //列表下载
    downloadExcel() {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => {},
      });
    },
    //数据写入excel
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = [
          "用餐时段",
          "订单编号",
          "订单明细",
          "金额",
          "姓名",
          "手机",
          "用餐日期",
          "用餐状态",
          "报餐时间",
          "核销时间"
        ]; // 导出的表头名信息
        const filterVal = [
          "mealTime",
          "order_number",
          "orderDetails",
          "money",
          "name",
          "phone",
          "repast_date",
          "status",
          "created_at",
          "updated_at",
        ]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "现场点餐数据"); // 导出的表格名称，根据需要自己命名
        // this.userLogFn();
      });
    },
    //格式转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    //获取导出的数据
    async getExportData() {
      this.ExportLoading = true;
      this.reGetExportData = false;
      let param = {
        ...this.outSideParam,
        no_paging: 1
      };
      try {
        let {data} = await listOutsiderOrders(param);
        this.dataForExport = data.map((item) => {
            if (item.get_order_info instanceof Array) {
              let arr = [];
              item.get_order_info.forEach((element) => {
                let str = "";
                str = `${element.title}*${element.num}份`;
                arr.push(str);
              });
              if (arr.length > 1) {
                arr.join(",");
              }
              item.orderDetails = arr.toString();
            } else {
              let obj = item.get_order_info;
              item.orderDetails = `${obj.title}*${item.count}份`;
            }
            item = {
              ...item,
              name: item.get_user.realname,
              phone: item.get_user.mobile,
              mealTime: item.get_meal_time.title,
              status:
                item.check_status == 1
                  ? "已核销"
                  : "待核销",
              updated_at: item.check_status == 1 ? item.updated_at : "待核销",
              money: item.money * 1,
            };
            return item;
          });
          // console.log('外部订单不分页数据')
          // console.log(this.dataForExport)
        this.reGetExportData = false;
        this.ExportLoading = false;
      } catch (error) {
        if (error.status == 500) {
            this.$message.error(
              "加载导出的数据超时，请点击重新获取导出数据按钮获取数据"
            );
            this.reGetExportData = true;
          }
      }
    },
    //获取表格数据
    getOutSideOrders() {
      this.loading2 = true;
      //获取分页的数据用于展示
      this.outSideParam.no_paging = 0;

      listOutsiderOrders(this.outSideParam).then((res) => {
        const pagination = { ...this.pagination2 };
        pagination.total = res.data.total;
        this.pagination2 = pagination;

        res.data.data.forEach((item) => {
          if (item.get_order_info.length != 0) {
            let arr = [];
            item.get_order_info.forEach((subItem) => {
              let str = "";
              str = `${subItem.title}*${subItem.num}份`;
              arr.push(str);
            });
            if (arr.length > 1) {
              arr.join(",");
            }
            item.orderDetails = arr.toString();
          }
        });
        this.outSideOrders = res.data.data;
        this.loading2 = false;
      });
    },
    //分页
    handleTableChange2(pagination) {
      this.pagination2.current = pagination.current;
      this.pagination2.pageSize = pagination.pageSize;
      this.outSideParam.page = pagination.current;
      this.getOutSideOrders();
    },
    //获取就餐时段列表
    mealtimeListFn() {
      mealtimeListWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects2[0].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getOutSideOrders()
    this.mealtimeListFn()
    this.getExportData()
    this.getSceneStatus()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.agent_inst = JSON.parse(localStorage.getItem("agent_inst"));
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.controlBox {
  display: flex;
  .btn {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
.flex {
  display: flex;
  width: 220px;
  justify-content: space-between;
}
.tip {
  color: gray;
}
.export-btn{
  margin-top: 10px;
}
.scene-switch .ant-switch-checked{
  background-color:#FF833D;
}
</style>